import React, { useEffect, useState, useRef } from "react";
import "./Home.css";
import colors from "../../utils/colors.json";
import Header from "../../components/Header/Header";
import Menu from "../../components/Menu/Menu";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import ContentHub from "../../components/Content/ContentHub";
import useAdminPermission from "../../utils/stores/useAdminPermission";
import useEditableStore from "../../utils/stores/useEditableStore";
import homedata from "../../utils/data/returnPageHome.json";
import { createArrayOfContents } from "../../utils/utils";
import makeRequest from "../../utils/methods/makeRequest";
import { useNavigate, useParams } from "react-router";
import AdminBar from "../../components/AdminBar/AdminBar";
import getCsrfToken from "../../utils/methods/getCsrfToken.js";

function Home() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [pagedata, setPageData] = useState();
  const [contents, setContents] = useState();
  const setEditable = useEditableStore(state => state.setEditable);
  const editable = useEditableStore(state => state.editable);
  const navigate = useNavigate();
  const params = useParams();
  const adminPermission = useAdminPermission(state => state.adminPermission);
  const setAdminPermission = useAdminPermission(state => state.setAdminPermission);

  const contentObj = useRef({
    page_path: params.page_path,
    title: "",
    subtitle: "",
    image: "",
    video: "",
    text: "",
    assets: "",
    direct_link: "",
  });

  window.addEventListener("resize", () => {
    setInnerHeight(window.innerHeight);
  });

  const getHomeContents = () => {
    var url = process.env.REACT_APP_SERVER_URL + "/returnPage/";
    var data = new FormData();
    data.append("page_path", "/");

    getCsrfToken().then(res => {
      // setCookie("csrftoken", res.data, 1);
      makeRequest(url, data, "post", false, res.data)
        .then(res => res.data)
        .then(data => {
          setPageData(data);
          setContents(createArrayOfContents(data.position_set));
        })
        .catch(err => {
          // console.log(err);
        });
    });
  };

  const checkAuth = () => {
    var url = process.env.REACT_APP_SERVER_URL + "/sso/";
    getCsrfToken().then(res => {
      fetch(url)
        .then(res => {
          return res.json();
        })
        .then(data => {
          if (data.data && data.data.status === 403) {
            navigate("/login");
          } else {
            var userData = JSON.stringify(data);
            window.sessionStorage.setItem("user", userData);
            window.sessionStorage.setItem("user-token", data.token);
            if (data.cms == true) {
              setAdminPermission(true);
            }
          }
        })
        .catch(err => {
          // console.log(err);
        });
    });
  };

  useEffect(() => {
    checkAuth();
    getHomeContents();
    var edit = window.sessionStorage.getItem("editable");    
    if (edit == "false"){
      edit = false;
    }else{
      edit = true
    }
    setEditable(edit);
  }, []);

  const handleAddContent = e => {
    var id = pagedata.position_set.length;
    if (id < 0) {
      id = 0;
    }
   
    setContents(contents => [
      ...contents,
      <ContentHub autoEdit={true} length={pagedata.position_set.length} datacontent={contentObj.current} key={contents.length - 1} id={id} />,
    ]);
  };

  return (
    <div className="Home" style={{ minHeight: innerHeight + "px" }}>
      {adminPermission && <AdminBar />}

      <Header
        isopen={isMenuOpen ? 1 : 0}
        setIsOpen={setIsMenuOpen}
        header={true}
        menu={true}
        theme={"top"}
        style={{ padding: "var(--default-padding)" }}
      />

      {homedata.menu && <Menu isopen={isMenuOpen ? 1 : 0} />}

      {homedata.contents.length > 0 && <h2 className="home-title">FCB / Busca</h2>}
      <div className="home-news">{contents}</div>

      {editable && (
        <div className="add-content">
          <Button
            onClick={handleAddContent}
            type="fill"
            className={"add-btn"}
            icon={"add"}
            label={"Add content"}
            color_set={{
              default: colors.fcb_pink,
              hover: colors.fcb_pink_shaddow,
            }}
          />
        </div>
      )}

      {homedata.footer && <Footer />}
    </div>
  );
}

export default Home;
