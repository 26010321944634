const checkSuperUser = (type, user) => {
  switch (type) {
    case "list":
      //console.log("USER:"+ user)
      var sUsers = ["Anna Martha", "Juscelino Vieira"];
      if (sUsers.indexOf(user.name) > -1) {
        return true;
      } else {
        return false;
      }

    case "ranking":
      var sUsers = ["Anna Martha", "Juscelino Vieira"];
      if (sUsers.indexOf(user.name) > -1) {
        return true;
      } else {
        return false;
      }
  }
};

export default checkSuperUser;
