import React, { useEffect, useState } from "react";
import { LoginComp } from "./style";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import useAdminPermission from "../../utils/stores/useAdminPermission";

import { useNavigate } from "react-router";
import makeRequest from "../../utils/methods/makeRequest";
import getCsrfToken from "../../utils/methods/getCsrfToken.js";

const Login = ({ ...props }) => {
  var genders = ["o", "e", "a"];
  const [gender, setGender] = useState(genders[0]);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const setAdminPermission = useAdminPermission(state => state.setAdminPermission);
  const navigate = useNavigate();

  const checkAuth = () => {
    var url = process.env.REACT_APP_SERVER_URL + "/sso/";
    getCsrfToken().then(res => {
      makeRequest(url, "", "get", "", res.data)
        .then(res => {
          return res.json();
        })
        .then(data => {
          console.log(data);
          if (data.data && data.data.status === 403) {
            navigate("/login");
          } else {
            var userData = JSON.stringify(data);
            window.sessionStorage.setItem("user", userData);
            window.sessionStorage.setItem("user-token", data.token);
            window.sessionStorage.setItem("poll_super", data.poll_super);
            if (data.cms == true) {
              setAdminPermission(true);
              navigate("/");
            }
          }
        })
        .catch(err => {
          // console.log(err);
        });
    });
  };

  const handleLogin = () => {
    window.sessionStorage.setItem("origin", "busca");
    var url = process.env.REACT_APP_SERVER_URL + "/auth/sign_in";
    window.location.href = url;
  };

  window.addEventListener("resize", () => {
    setInnerHeight(window.innerHeight);
  });

  useEffect(() => {

    //checkAuth();
    var i = 0;
    setInterval(() => {
      setGender(genders[i]);
      i = i + 1;
      if (i > genders.length - 1) {
        i = 0;
      }
    }, 500);
  }, []);

  return (
    <LoginComp innerheight={innerHeight} {...props}>
      <Header header={true} login={true} theme={"page"} handleLogin={handleLogin} />
      <div className="login-welcome">
        <h2 className="login-welcome-h2">
          Bem-vind{gender} <br /> ao FCB/Busca
        </h2>
        <Button onClick={handleLogin} className={"add-btn"} label="Entrar" color_set={{ default: "#fff", hover: "gray" }} />
      </div>
      <Footer />
    </LoginComp>
  );
};

export default Login;
